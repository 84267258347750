import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { graphql, useStaticQuery } from "gatsby";

import ContactForm from "../components/ContactForm";
import SEO from "../components/common/seo";
import { routePaths } from "../constants/routes";

const query = graphql`
  query {
    contact: strapiContactForm {
      strapi_id
      referencing {
        metaTitle
        metaDescription
        metaImage {
          strapi_id
          url
          alternativeText
        }
        words {
          word
        }
      }
    }
  }
`;

const ContactPage = () => {
  const { contact } = useStaticQuery(query);

  const { metaTitle, metaDescription, metaImage, words } = contact.referencing;

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        image={metaImage?.url}
        pathname={routePaths.contact}
        keywords={words}
      />
      <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA}>
        <ContactForm />
      </GoogleReCaptchaProvider>
    </>
  );
};

export default ContactPage;
