import { Autocomplete, Button, TextField } from '@mui/material'
import { Home, ContactMail } from '@mui/icons-material'
import React, { useState, useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { isEmpty } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'

import { useAppContext } from '../../contexts/appContext'
import Header from '../common/Header'
import { routePaths } from '../../constants/routes'
import { createContact } from '../../helpers/api'
import { Form } from './Styled'
import { formatOptions } from './helpers'
import { SubContainer } from '../common/Styled'
import { getParams } from '../../helpers/urlParams'

const query = graphql`
  query {
    home: strapiHome {
      referencing {
        metaTitle
      }
    }
    contact: strapiContactForm {
      referencing {
        metaTitle
      }
    }
    toOrder: strapiToOrder {
      strapi_id
      products {
        strapi_id
        title
      }
    }
  }
`

const ContactForm = () => {
  const data = useStaticQuery(query)
  const id = getParams('id')?.id as string
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [toOrder, setToOrder] = useState<{
    label: string
    value: string
  } | null>(null)

  const {
    contact,
    home,
    toOrder: { products }
  } = data

  useEffect(() => {
    if (id)
      setToOrder({
        value: id,
        label:
          products.find(product => product.strapi_id === parseInt(id, 10))
            ?.title || ''
      })
  }, [id, products])

  const showSnackbar = useAppContext()?.showSnackbar

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleSubmit = async e => {
    e.preventDefault()
    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha('submit')
    try {
      await createContact(name, message, email, toOrder?.value, token)
      showSnackbar('success', 'Message envoyé !')
      setName('')
      setEmail('')
      setMessage('')
      setToOrder(null)
    } catch (e) {
      showSnackbar('error', "Une erreur s'est produite, veuillez réesayer")
      console.error(e)
    }
  }

  const handleChangeToOrder = (_, value) => {
    setToOrder(value)
  }

  const title = contact.referencing.metaTitle

  const items = [
    {
      icon: <Home />,
      label: home.referencing.metaTitle,
      route: routePaths.home
    },
    {
      icon: <ContactMail />,
      label: title
    }
  ]

  return (
    <SubContainer>
      <Header {...{ items, title }} />
      <Form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          type="text"
          value={name}
          required
          style={{
            minWidth: 100,
            width: '100%',
            maxWidth: 500,
            borderColor: 'white',
            height: 'fit-content'
          }}
          label="Nom"
          onChange={e => setName(e.target.value)}
        />
        <TextField
          variant="outlined"
          type="text"
          inputMode="email"
          value={email}
          InputProps={{
            type: 'email'
          }}
          required
          style={{
            minWidth: 100,
            width: '100%',
            maxWidth: 500,
            borderColor: 'white',
            height: 'fit-content'
          }}
          label="Email"
          onChange={e => setEmail(e.target.value)}
        />
        {!isEmpty(products || []) && id && (
          <Autocomplete
            id="to-order-field"
            clearOnBlur
            freeSolo
            onChange={handleChangeToOrder}
            getOptionLabel={option =>
              typeof option !== 'string' && option?.label
            }
            value={toOrder}
            options={formatOptions(products)}
            renderInput={params => (
              <TextField label="Produit sur commande concerné" {...params} />
            )}
            style={{
              minWidth: 100,
              width: '100%',
              maxWidth: 500,
              borderColor: 'white',
              height: 'fit-content'
            }}
          />
        )}
        <TextField
          variant="outlined"
          type="text"
          value={message}
          required
          multiline
          rows={5}
          maxRows={5}
          style={{
            minWidth: 100,
            width: '100%',
            maxWidth: 500,
            borderColor: 'white',
            height: 'fit-content'
          }}
          label="Rédigez votre Message"
          onChange={e => setMessage(e.target.value)}
        />
        <Button variant="contained" color="primary" type="submit">
          Envoyer
        </Button>
      </Form>
    </SubContainer>
  )
}

export default ContactForm
