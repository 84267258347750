import { styled } from "@mui/material/styles";

export const Form = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
`;
